<template>
  <div>
    <el-row style="margin-bottom: 20px" :gutter="20">
      <el-col style="padding-right: 8px; margin-bottom: 30px">
        <yearsLineChart
          class="echart_card"
          :id="'yearsLineChart'"
          height="500px"
          width="100%"
        />
      </el-col>

      <el-col style="padding-right: 8px; margin-bottom: 30px">
        <saleTimeLineChart
          class="echart_card"
          :id="'saleTimeLineChart'"
          height="500px"
          width="100%"
        />
      </el-col>

      <el-col style="padding-right: 8px; margin-bottom: 30px">
        <ProductPieChart
          class="echart_card"
          :id="'ProductPieChart'"
          height="500px"
          width="100%"
        />
      </el-col>

      <el-col style="padding-right: 8px; margin-bottom: 30px">
        <productPricePieChart
          class="echart_card"
          :id="'productPricePieChart'"
          height="500px"
          width="100%"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ProductPieChart from './chart/productPieChart'
import productPricePieChart from './chart/productPricePieChart.vue'
import saleTimeLineChart from './chart/saleTimeLineChart.vue'
import yearsLineChart from './chart/yearsLineChart.vue'
export default {
  components: {
    ProductPieChart,
    productPricePieChart,
    saleTimeLineChart,
    yearsLineChart,
    contractLineChart,
  },
}
</script>

<style lang="scss" scoped>
.echart_card {
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
