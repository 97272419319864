<template>
  <div>
    <div style="text-align: right; margin-bottom: 10px; overflow: hidden">
      <h2 style="float: left">商品采购金额占比饼状图</h2>

      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="timeFilterType"
        @change="timerFilterTypeSelectChange"
      >
        <el-option
          v-for="item in timerFilterTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        style="margin-right: 20px"
        size="mini"
        v-model="productType"
        @change="productTypeSelectChange"
      >
        <el-option
          v-for="item in productTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        size="mini"
        v-model="timer"
        type="datetimerange"
        align="right"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        @change="mothRangeChange"
      >
      </el-date-picker>
    </div>

    <div
      v-loading="loading"
      :id="id"
      :class="className"
      :style="{ height: height, width: width }"
    ></div>
    <div>总采购金额:¥ {{ saleAllPrice }}</div>
  </div>
</template>

<script>
import { timeOffset } from '@/utils/zoneTime.js'
import * as echarts from 'echarts'
import resize from './mixins/resize'
import {
  getMyReporProduct,
  getTimeFilterOptions,
  getProductTypeOptions,
} from '@/api/outStorage.js'
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    height: {
      type: String,
      default: '200px',
    },
    width: {
      type: String,
      default: '200px',
    },
  },
  created() {},
  async mounted() {
    var now = new Date() //当前日期
    var nowMonth = now.getMonth() //当前月
    var nowYear = now.getFullYear() //当前年
    //本月的开始时间
    var timeStar = new Date(nowYear, nowMonth, 1)
    //本月的结束时间
    var timeEnd = new Date(nowYear, nowMonth + 1, 0, 23, 59, 59)

    this.timer = [timeStar, timeEnd]

    let { data } = await getTimeFilterOptions()

    this.timeFilterType = data[0].value
    this.timerFilterTypeOptions = data

    let { data: productType } = await getProductTypeOptions()

    this.productType = productType[0].value
    this.productTypeOptions = productType

    this.getReport()
  },
  data() {
    return {
      loading: false,
      chart: null,
      data: null,
      timer: '',
      userOption: [],
      timeFilterType: null,
      timerFilterTypeOptions: [],
      productType: null,
      productTypeOptions: [],
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    timerFilterTypeSelectChange(val) {
      this.timeFilterType = val
      this.getReport()
    },
    productTypeSelectChange(val) {
      this.productType = val
      this.getReport()
    },
    initChart() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }

      this.chart = echarts.init(document.getElementById(this.id))

      let option = {
        title: {
          text: '',
          subtext: '',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : ¥ {c} ({d}%)',
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          left: 10,
          top: 20,
          bottom: 20,
        },
        series: [
          {
            name: '商品消费金额',
            type: 'pie',
            radius: '50%',
            data: this.data ?? [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      option && this.chart.setOption(option)
    },
    async getReport() {
      this.loading = true
      let timerFilter = {}

      if (this.userId) {
        timerFilter.userId = this.userId
      }

      if (this.timeFilterType) {
        timerFilter.timeFilterType = this.timeFilterType
      }

      if (this.productType) {
        timerFilter.productType = this.productType
      }

      if (this.timer) {
        timerFilter.startTime = timeOffset(this.timer[0])
        timerFilter.endTime = timeOffset(this.timer[1])
      }

      let { data } = await getMyReporProduct(timerFilter)

      this.data = data
      this.initChart()
      this.loading = false
    },
    mothRangeChange(timer) {
      this.getReport()
    },
  },
  computed: {
    saleAllPrice() {
      let sumPrice = 0
      if (!this.data) {
        return sumPrice
      }

      this.data.forEach((item) => {
        sumPrice += item.value
      })

      return sumPrice.toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped></style>
